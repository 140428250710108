import { createSlice } from '@reduxjs/toolkit';
import { fetchProductListSlice, fetchFirmwaresSlice, fetchReleaseNotesSlice } from './operations';
import { logOut } from '../auth/operations';

const handlePending = state => {
    state.isLoading = true;
};

const handleRejected = (state, action) => {
    state.isLoading = false;
    state.error = action.payload;
};

const productListSlice = createSlice({
    name: 'firmware',
    initialState: {
        product: [],
        firmware: [],
        releaseNotes: '',
        isLoading: false,
        error: null,
    },
    extraReducers: builder => {
        builder
            .addCase(fetchProductListSlice.pending, handlePending)
            .addCase(fetchProductListSlice.fulfilled, (state, action) => {
                state.isLoading = false;
                state.error = null;
                state.product = action.payload;
            })
            .addCase(fetchProductListSlice.rejected, handleRejected)
            .addCase(fetchFirmwaresSlice.pending, handlePending)
            .addCase(fetchFirmwaresSlice.fulfilled, (state, action) => {
                state.isLoading = false;
                state.error = null;
                state.firmware = action.payload;
            })
            .addCase(fetchFirmwaresSlice.rejected, handleRejected)
            .addCase(fetchReleaseNotesSlice.pending, handlePending)
            .addCase(fetchReleaseNotesSlice.fulfilled, (state, action) => {
                state.isLoading = false;
                state.error = null;
                state.releaseNotes = action.payload;
            })
            .addCase(fetchReleaseNotesSlice.rejected, handleRejected)
            .addCase(logOut.fulfilled, state => {
                state.product = [];
                state.firmware = [];
                state.releaseNotes = '';
                state.isLoading = false;
                state.error = null;
            })
    },
});

export default productListSlice.reducer;
