import { configureStore } from '@reduxjs/toolkit';
import currentSectionReducer from './slices/currentSectionSlice';
import tasks from './slices/tasks/slice';
import completedTasks from './slices/completedTasks';
import authSlice from './slices/auth/slice';
import usersSlice from './slices/users/slice';
import productListSlice from './slices/firmwares/slice';
import ticketsSlice from './slices/tickets/slice';

import {
    persistStore,
    persistReducer,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';


const authPersistConfig = {
    key: 'auth',
    storage,
    whitelist: ['isLoggedIn'],
};

export const store = configureStore({
    reducer: {
        currentSection: currentSectionReducer,
        tasks: tasks,
        completedTasks: completedTasks,
        auth: persistReducer(authPersistConfig, authSlice),
        users: usersSlice,
        productList: productListSlice,
        tickets: ticketsSlice,
    },
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
            },
        }),
    devTools: process.env.NODE_ENV === 'development',
})

export const persistor = persistStore(store);
